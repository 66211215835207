<template>
  <div class="flex items-center w-full justify-between space-x-4 ml-4">
    <div
      v-if="canCreateOfferNote"
      v-tooltip="{
        content: $t('OFFERS.NOTES.TRIGGER_DESCRIPTION'),
        maxWidth: 200
      }"
      data-cy="offer-note-prompt-button"
      @click.stop="showPrompt = true"
    >
      <icon-base
        ref="promptTrigger"
        :class="{ 'text-primary': !!existingNote || showPrompt }"
        :icon="IconOfferNote"
        width="24"
        height="24"
      />
    </div>
  </div>

  <teleport to="#modals">
    <prompt-box
      v-if="showPrompt"
      class="fixed z-20"
      :style="`top: ${promptTop}px; left: ${promptLeft}px;`"
      :title="
        $t('OFFERS.NOTES.PROMPT_TITLE', {
          funderName: funder?.full_name ?? '-'
        })
      "
      :description="$t('OFFERS.NOTES.PROMPT_DESCRIPTION')"
      :placeholder="$t('OFFERS.NOTES.TITLE')"
      :value="existingNote as string"
      :actions-disabled="isNoteLoading"
      deletable
      @cancel="showPrompt = false"
      @delete="saveOfferNote(null)"
      @save="saveOfferNote"
    />
  </teleport>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import IconOfferNote from "@/components/icons/IconOfferNote.vue";
import PromptBox, {
  PROMPT_HEIGHT,
  PROMPT_WIDTH
} from "@/components/ui/PromptBox.vue";
import IconBase from "@/components/ui/IconBase.vue";
import { useStore } from "vuex";
import { usePromiseWrapper } from "@/hooks/common";
import type { IFunder, IOffer } from "@/models/funders";
import { useNotification } from "@/hooks/notifications";
import { useI18n } from "vue-i18n";
import { usePermissions } from "@/hooks/auth";
import { Ability, PermissionSubject } from "@/enums/auth";
import { useElementBoundingLimited } from "@/hooks/elements";

interface Props {
  offerDetail: IOffer;
  funder?: IFunder;
}

const props = defineProps<Props>();
const { dispatch } = useStore();

const existingNote = computed(
  () =>
    props.offerDetail?.offer_details?.find((field) => field.name === "note")
      ?.value
);

const promptTrigger = ref<HTMLDivElement | null>(null);
const showPrompt = ref(false);
const { top: promptTop, left: promptLeft } = useElementBoundingLimited(
  promptTrigger,
  { offsetLeft: PROMPT_WIDTH, offsetTop: PROMPT_HEIGHT }
);
const { showMessage } = useNotification();
const { t } = useI18n();
const { canPerformActionReactive } = usePermissions();

const canCreateOfferNote = canPerformActionReactive(
  PermissionSubject.offerNote,
  Ability.create,
  { offer: props.offerDetail.id }
);

const { fetchWrapper: saveOfferNote, loading: isNoteLoading } =
  usePromiseWrapper(async (note: string | null) => {
    const message = existingNote.value
      ? t("OFFERS.NOTES.UPDATED")
      : t("OFFERS.NOTES.ADDED");
    await dispatch("applications/attachOfferNote", {
      offerId: props.offerDetail.id,
      note
    });
    showPrompt.value = false;
    showMessage(message);
  });
</script>
